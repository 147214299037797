import { create } from 'zustand'
import produce from 'immer'
import { createTrackedSelector } from 'react-tracked'
import { YapiGetV1CoinQueryMainCoinListCoinListData } from '@/typings/yapi/CoinQueryMainCoinListV1GetApi'

type IStore = ReturnType<typeof getStore>
export type IMarketSpotStore = IStore

function getStore(set, get) {
  return {
    allCoinSymbolInfo: [] as YapiGetV1CoinQueryMainCoinListCoinListData[],
    udpateAllCoinSymbolInfo(data: YapiGetV1CoinQueryMainCoinListCoinListData[]) {
      set(
        produce((state: IStore) => {
          state.allCoinSymbolInfo = data
        })
      )
    },
  }
}

const baseMarketStore = create<IStore>(getStore)

const useMarketStore = createTrackedSelector(baseMarketStore)

export { useMarketStore, baseMarketStore }
